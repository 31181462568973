<template>
   <div class="addassociate">
       <el-card shadow="never">
           <template #header>
               新建关联
           </template>
           <div class="content">
               <el-form :model="ainfo" ref="ainfo" label-width="150px;">
                   <el-form-item label="关联名称:" prop="AssociateName" required :rules="{required:true,message:'必须填写关联名称',trigger:'blur'}">
                       <el-input v-model="ainfo.AssociateName" style="width:300px;" size="small"></el-input>
                   </el-form-item>
                   <el-form-item label="选择商品:" prop="PdtCode">
                       <el-input v-model="ainfo.PdtCode" style="width:300px;margin-right:20px;" placeholder="请输入商品编号添加" size="small"></el-input>
                       <el-button type="success" @click="confirm" size="small" :disabled="associatepdt.length?true:false">确认添加</el-button>
                       <el-button type="primary" @click="getassociate" size="small" :disabled="associatepdt.length?false:true">添加可关联产品</el-button>
                       <br>
                       <el-tag type="primary">注意：仅支持通过商品编号进行添加</el-tag>
                   </el-form-item>
                   <el-divider></el-divider>
                   <div class="params-block" v-if="curcate">
                       <h1>品类：{{curcate}}</h1>
                       <h1>品牌：{{curbrand}}</h1>
                       <h1>型号：{{curmodel}}</h1>
                   </div>
                    <el-table :data="associatepdt" border style="width: 60%; margin: 1em 0" size="small">
                        <el-table-column label="商品主图" prop="PdtThumb" width="80px" align="center">
                            <template #default="scope">
                                <el-image :src="scope.row.PdtThumb"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品编号/id" align="center" width="150px">
                            <template #default="scope">
                                <span>{{scope.row.PdtCode}}:{{scope.row.ID}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品名称" prop="PdtName" align="center"></el-table-column>
                        <el-table-column label="所属商户" prop="Merchant" width="200px" align="center"></el-table-column>
                        <el-table-column label="操作" width="80px" align="center">
                            <template #default="scope">
                                <el-button @click="remove(scope.$index,scope.row.ID)" type="text" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
               </el-form>
               <div class="footer">
                   <el-button type="primary" @click="save" :disabled="executing">确认</el-button>
                   <el-button type="cancel" @click="cancel">取消</el-button>
               </div>
           </div>
       </el-card>
    </div>
    <el-dialog v-model="add" title="添加可关联产品">
        <div>
            <el-input v-model="selpdtkeyword" size="small" placeholder="请输入商品编号/商品ID/商品名称" style="width:250px;margin-right:20px;"></el-input>
            <el-button @click="search" type="primary" size="small">搜索</el-button>
            <el-table :data="relatetable" border size="small" style="margin-top:20px;margin-bottom:20px;" @selection-change="setass">
                <el-table-column type="selection" width="40"></el-table-column>
                <el-table-column label="商品主图" prop="PdtThumb" width="80px" align="center">
                    <template #default="scope">
                        <el-image :src="scope.row.PdtThumb"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="商品编号/id" align="center" width="150px">
                    <template #default="scope">
                        <span>{{scope.row.PdtCode}}:{{scope.row.ID}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品名称" prop="PdtName" align="center"></el-table-column>
                <el-table-column label="所属商户" prop="Merchant" width="200px" align="center"></el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-button type="primary" @click="saveass" size="small">确认</el-button>
            <el-button type="cancel" @click="cancelass" size="small">取消</el-button>
        </div>
    </el-dialog>
</template>
<style scoped>
h1 {
    margin-top:0;
    margin-bottom:0;
    margin-right:50px;
}
.params-block {
    display:flex;
    justify-content: flex-start;
    align-items:center;
}
</style>
<script>
import qs from "qs"
import constant from "@/constant"
export default {
    data(){
        return {
            curid:'',
            add:false,
            curcate:'',
            curbrand:'',
            curmodel:'',
            ainfo:{
                AssociateName:'',
                PdtCode:'',
                selids:[],
            },
            associatepdt:[],
            relatetable:[],
            associatetable:[],
            selpdtkeyword:'',
            sel:[],
            executing:false,
            
        }
    },
    created:function(){
        let id = this.$route.query.id;
        if(id){
            this.axios.get(constant.getassdetail,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.ainfo = response.data.info;
                this.associatepdt = response.data.pdts;
            })
        }
    },
    methods:{
        
        confirm(){
            if(!this.ainfo.PdtCode){
                this.$message.error("请填写产品编号");
                return false;
            }
            //console.log(this.ainfo.PdtCode);
            this.axios.get(constant.targetpdt,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    pdtcode:this.ainfo.PdtCode
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data.msg == "NO"){
                    this.$message.error("查询不到产品");
                }else{
                    this.associatepdt.push(response.data.info);
                    this.curcate = response.data.info.Category;
                    this.curbrand = response.data.info.Brand;
                    this.curmodel = response.data.info.Model;
                    this.curid = response.data.info.ID;
                }
                
            });
        },
        save(){
            if(this.associatepdt.length<2){
                this.$message.error("必须选择关联产品(至少需要2件商品)");
                return false;
            }
            
            if(!this.ainfo.AssociateName){
                this.$message.error("必须填写关联名称");
                return false;
            }
            this.ainfo.selids = [];
            for(let k = 0;k < this.associatepdt.length; k++){
                this.ainfo.selids.push(this.associatepdt[k]['ID']);
            }
            console.log(this.ainfo);
            this.executing = true;
            this.axios.post(constant.saveass,qs.stringify(this.ainfo),{
                headers:{
                    "content-type":"application/x-www-form-urlencoded"
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.$message.success("操作成功");
                    this.$router.go(-1);
                }else{
                    this.$message.success("操作失败");
                }
                this.executing = false;

            });
        },
        cancel(){
            this.$router.go(-1);
        },
        remove(index,id){
            if(index != -1){
                this.associatepdt.splice(index,1);
                let id_index = this.ainfo.selids.indexOf(id);
                if(id_index != -1)
                    this.ainfo.selids.splice(id_index,1);
            }
        },
        getassociate(){
            if(!this.ainfo.PdtCode){
                this.$message.error("请选择需要关联的产品");
                return false;
            }
            this.axios.get(constant.getassociate,{
                headers:{
                    "content-type":"application"
                },
                params:{
                    pdtcode:this.ainfo.PdtCode
                }
            }).then((response)=>{
                console.log(response.data);
                this.relatetable = response.data;
                this.associatetable = response.data;
                this.add = true;
            });
        },
        cancelass(){
            this.add = false;
        },
        saveass(){
            //console.log(this.sel);
            for(let j = 0;j<this.sel.length;j++){
                
                console.log(this.ainfo.selids.indexOf(this.sel[j]['ID']));
                if(this.ainfo.selids.indexOf(this.sel[j]['ID'])== -1){
                    this.associatepdt.push(this.sel[j]);  
                }
                    
            }
            this.add = false;
        },
        setass(selection){
            console.log(selection);
            this.sel = selection;
        },
        search(){
            if(this.selpdtkeyword){
                let newarr = Array();
                for(let i=0;i<this.relatetable.length;i++){
                    let cur_item = this.relatetable[i];
                    if(cur_item['PdtName'].indexOf(this.selpdtkeyword) != -1 || cur_item['PdtCode'].indexOf(this.selpdtkeyword) != -1 || cur_item['ID'].indexOf(this.selpdtkeyword) != -1){
                        newarr.push(cur_item);
                    }
                }
                this.relatetable = newarr;
            }else{
                this.relatetable = this.associatetable;
            }
            
        }
    }
}
</script>